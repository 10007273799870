export default {
    global: {
        outer: 'mb-4 w-full',
        inner: 'relative',
        label: 'block mb-2 leading-tight text-black text-base',
        input: 'block w-full text-black px-4 py-2 bg-white border border-gray-medium  text-base focus:outline-none placeholder-gray-medium focus:ring-1 focus:ring-red focus:border-red  min-h-10 -my-[1px]',
        help: 'text-xs  mt-2',
        messages: 'list-none p-0 mt-3 mb-3 font-semi text-xs text-red  ',
        message: 'mb-1 flex items-center gap-2 leading-tight',
        prefixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 left-3 pointer-events-none',
        suffixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none',
    },
    search: {
        input: 'pl-10 appearance-none',
    },
    button: {
        input: '$reset relative w-full px-4 py-2 text-white transition-colors duration-300 border border-transparent rounded bg-red hover:bg-red-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:red min-h-10 flex-center focus:text-white',
        wrapper: 'mt-4',
    },
    checkbox: {
        input: '$reset block w-4 h-4 mr-4 rounded accent-red focus:accent-red focus:outline-none disabled:bg-gray-100',
        wrapper: 'flex items-center',
        label: '$reset block leading-tight mb-0 select-none',
        decorator: 'hidden',
    },
    select: {
        outer: 'min-w-20',
        input: 'appearance-none',
        selectIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none bg-white border-gray-medium',
    },
    textarea: {
        input: 'h-43',
    },
    radio: {
        legend: 'block mb-2 leading-tight font-medium ',
        wrapper: 'flex items-center gap-3',
        label: '$reset disabled:text-gray-light',
        input: '$reset focus:ring-transparent h-4 w-4 text-red border-black appearance-none rounded-full bg-white border checked:bg-red disabled:bg-gray-100 checked:bg-red  disabled:text-gray-light',
        inner: 'h-6',
        options: 'flex flex-col gap-2',
    },
};
